define("@ember-data/store/index", ["exports", "@ember-data/store/index-0b6637cb"], function (_exports, _index0b6637cb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.C;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.S;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.n;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.r;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _index0b6637cb.s;
    }
  });
});