define("ember-svg-jar/inlined/logo-square-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" fill-opacity=\".4\" fill-rule=\"evenodd\" d=\"M26.67 11.233L32 2 16 29.713 0 2.001h10.809l10.595 18.351 5.265-9.12z\" clip-rule=\"evenodd\"/><path fill=\"#fff\" fill-opacity=\".7\" fill-rule=\"evenodd\" d=\"M21.339 2l5.33 9.233 5.33-9.232-10.595 18.353L10.808 2h10.53z\" clip-rule=\"evenodd\"/><path fill=\"#fff\" d=\"M26.67 11.233L21.338 2H32l-5.33 9.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});