define("ember-svg-jar/inlined/person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.358 3.066a15.556 15.556 0 1117.284 25.868A15.556 15.556 0 017.358 3.066zm1.975 22.245v2.233a13.245 13.245 0 0013.334 0v-2.222a3.578 3.578 0 00-.893-2.568 3.578 3.578 0 00-2.44-1.198h-6.667a3.578 3.578 0 00-3.334 3.755zm15.556-.078v.656a13.334 13.334 0 00-1.328-20.92 13.334 13.334 0 00-15.122 0 13.333 13.333 0 00-1.328 20.92v-.656a5.778 5.778 0 015.556-5.9 5.556 5.556 0 01-1.8-6.57A5.555 5.555 0 0116 9.333a5.556 5.556 0 013.333 10 5.789 5.789 0 015.556 5.9zm-10.74-7.573a3.334 3.334 0 004.208-.414 3.334 3.334 0 00.415-4.209 3.334 3.334 0 00-3.422-1.417 3.333 3.333 0 00-2.62 2.619 3.333 3.333 0 001.418 3.421z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});