define("ember-svg-jar/inlined/building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.254 6.912H8.197V11.4h2.057V6.912zm0 6.732H8.197v4.488h2.057v-4.488zm6.17-6.732h-2.057V11.4h2.057V6.912zm0 6.732h-2.057v4.488h2.057v-4.488zm-6.17 6.732H8.197v4.488h2.057v-4.488zm6.17 0h-2.057v4.488h2.057v-4.488z\"/><path d=\"M30.822 13.644c0-.595-.216-1.166-.602-1.587a1.974 1.974 0 00-1.455-.657h-6.17V2.424c0-.596-.218-1.166-.603-1.587A1.974 1.974 0 0020.537.18H4.081c-.546 0-1.069.236-1.455.657-.385.42-.602.991-.602 1.587v29.172h28.798V13.644zM4.081 2.424h16.456v26.928H4.081V2.424zm18.513 26.928V13.644h6.171v15.708h-6.17z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});