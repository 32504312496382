define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 3.556v1.777h9.422L11.378 19.378l1.244 1.244L26.667 6.578V16h1.778V3.556H16z\"/><path d=\"M26.667 23.111v3.556H5.333V5.333H8.89V3.556H3.556v24.889h24.889V23.11h-1.778z\"/>",
    "attrs": {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});